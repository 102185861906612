// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contact-us-js": () => import("./../../../src/pages/contact-us.js" /* webpackChunkName: "component---src-pages-contact-us-js" */),
  "component---src-pages-cookie-policy-js": () => import("./../../../src/pages/cookie-policy.js" /* webpackChunkName: "component---src-pages-cookie-policy-js" */),
  "component---src-pages-covid-19-update-js": () => import("./../../../src/pages/covid-19-update.js" /* webpackChunkName: "component---src-pages-covid-19-update-js" */),
  "component---src-pages-eat-out-to-help-out-js": () => import("./../../../src/pages/eat-out-to-help-out.js" /* webpackChunkName: "component---src-pages-eat-out-to-help-out-js" */),
  "component---src-pages-events-host-your-own-events-js": () => import("./../../../src/pages/events/host-your-own-events.js" /* webpackChunkName: "component---src-pages-events-host-your-own-events-js" */),
  "component---src-pages-events-js": () => import("./../../../src/pages/events.js" /* webpackChunkName: "component---src-pages-events-js" */),
  "component---src-pages-events-upcoming-events-js": () => import("./../../../src/pages/events/upcoming-events.js" /* webpackChunkName: "component---src-pages-events-upcoming-events-js" */),
  "component---src-pages-events-upcoming-events-upcoming-events-sub-js": () => import("./../../../src/pages/events/upcoming-events/upcoming-events-sub.js" /* webpackChunkName: "component---src-pages-events-upcoming-events-upcoming-events-sub-js" */),
  "component---src-pages-exclusive-private-hire-js": () => import("./../../../src/pages/exclusive-private-hire.js" /* webpackChunkName: "component---src-pages-exclusive-private-hire-js" */),
  "component---src-pages-find-us-js": () => import("./../../../src/pages/find-us.js" /* webpackChunkName: "component---src-pages-find-us-js" */),
  "component---src-pages-food-and-drink-drinks-list-js": () => import("./../../../src/pages/food-and-drink/drinks-list.js" /* webpackChunkName: "component---src-pages-food-and-drink-drinks-list-js" */),
  "component---src-pages-food-and-drink-food-and-drink-detail-js": () => import("./../../../src/pages/food-and-drink/food-and-drink-detail.js" /* webpackChunkName: "component---src-pages-food-and-drink-food-and-drink-detail-js" */),
  "component---src-pages-food-and-drink-js": () => import("./../../../src/pages/food-and-drink.js" /* webpackChunkName: "component---src-pages-food-and-drink-js" */),
  "component---src-pages-gallery-js": () => import("./../../../src/pages/gallery.js" /* webpackChunkName: "component---src-pages-gallery-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-information-from-the-fox-club-js": () => import("./../../../src/pages/information-from-the-fox-club.js" /* webpackChunkName: "component---src-pages-information-from-the-fox-club-js" */),
  "component---src-pages-maintenance-js": () => import("./../../../src/pages/maintenance.js" /* webpackChunkName: "component---src-pages-maintenance-js" */),
  "component---src-pages-membership-js": () => import("./../../../src/pages/membership.js" /* webpackChunkName: "component---src-pages-membership-js" */),
  "component---src-pages-membership-membership-detail-js": () => import("./../../../src/pages/membership/membership-detail.js" /* webpackChunkName: "component---src-pages-membership-membership-detail-js" */),
  "component---src-pages-membership-membership-form-js": () => import("./../../../src/pages/membership/membership-form.js" /* webpackChunkName: "component---src-pages-membership-membership-form-js" */),
  "component---src-pages-membership-membership-thankyou-js": () => import("./../../../src/pages/membership/membership-thankyou.js" /* webpackChunkName: "component---src-pages-membership-membership-thankyou-js" */),
  "component---src-pages-page-2-js": () => import("./../../../src/pages/page-2.js" /* webpackChunkName: "component---src-pages-page-2-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-rooms-js": () => import("./../../../src/pages/rooms.js" /* webpackChunkName: "component---src-pages-rooms-js" */),
  "component---src-pages-rooms-rooms-detail-js": () => import("./../../../src/pages/rooms/rooms-detail.js" /* webpackChunkName: "component---src-pages-rooms-rooms-detail-js" */),
  "component---src-pages-sitemap-js": () => import("./../../../src/pages/sitemap.js" /* webpackChunkName: "component---src-pages-sitemap-js" */),
  "component---src-pages-terms-and-conditions-js": () => import("./../../../src/pages/terms-and-conditions.js" /* webpackChunkName: "component---src-pages-terms-and-conditions-js" */),
  "component---src-pages-the-fox-club-area-guide-js": () => import("./../../../src/pages/the-fox-club/area-guide.js" /* webpackChunkName: "component---src-pages-the-fox-club-area-guide-js" */),
  "component---src-pages-the-fox-club-js": () => import("./../../../src/pages/the-fox-club.js" /* webpackChunkName: "component---src-pages-the-fox-club-js" */),
  "component---src-pages-the-fox-club-our-history-js": () => import("./../../../src/pages/the-fox-club/our-history.js" /* webpackChunkName: "component---src-pages-the-fox-club-our-history-js" */),
  "component---src-templates-event-details-js": () => import("./../../../src/templates/event-details.js" /* webpackChunkName: "component---src-templates-event-details-js" */),
  "component---src-templates-food-and-drink-details-js": () => import("./../../../src/templates/food-and-drink-details.js" /* webpackChunkName: "component---src-templates-food-and-drink-details-js" */),
  "component---src-templates-membership-details-js": () => import("./../../../src/templates/membership-details.js" /* webpackChunkName: "component---src-templates-membership-details-js" */),
  "component---src-templates-room-details-js": () => import("./../../../src/templates/room-details.js" /* webpackChunkName: "component---src-templates-room-details-js" */)
}

